<template>
  <div class="flex flex-column gap-4">
    <div class="flex">
      <div class="flex gap-2">
        <b-button
          size="sm"
          variant="primary"
          class="text-nowrap"
          @click="createGroup()"
        >
          Add new group
        </b-button>
      </div>
    </div>

    <div class="font-bold">
      Showing {{ groups.length | format }} group(s)
    </div>

    <table class="table table-google">
      <thead>
        <tr>
          <th>ID</th>
          <th class="text-nowrap">Group Key</th>
          <th class="text-nowrap">Group Name</th>
          <th class="text-nowrap">Option Sorting</th>
          <th class="text-nowrap">Option Prefix</th>
          <th class="text-nowrap">Option Suffix</th>
          <th class="text-nowrap">Option Count</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody>
        <tr v-if="groups.length === 0">
          <td colspan="8" class="font-bold">
            <div v-if="isLoading">Loading..</div>
            <div v-else>No groups available</div>
          </td>
        </tr>

        <tr v-for="group in groups" :key="group.id">
          <td class="row-fit">{{ group.id }}</td>
          <td class="row-fit">{{ group.group_key }}</td>
          <td class="text-nowrap">{{ group.group_name }}</td>
          <td class="row-fit">{{ group.option_sort ? 'Automatic' : 'Manual' }}</td>
          <td class="row-fit text-nowrap">{{ group.option_prefix }}</td>
          <td class="row-fit text-nowrap">{{ group.option_suffix }}</td>
          <td class="row-fit">{{ group.option_count | format }}</td>
          <td class="row-fit">
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading"
              @click="editGroup(group)"
            >
              Edit
            </b-btn>
          </td>
        </tr>
      </tbody>
    </table>

    <dropdown-creator
      v-if="createModal.showModal"
      :data="createModal"
      @created="onCreated"
    />

    <dropdown-editor
      v-if="editModal.showModal"
      :data="editModal"
      @updated="onUpdated"
      @deleted="onDeleted"
    />
  </div>
</template>

<script>
const DropdownCreator = () => import('@/components/developer/DropdownCreator.vue');
const DropdownEditor = () => import('@/components/developer/DropdownEditor.vue');

export default {
  name: 'Dropdowns',
  components: {
    DropdownCreator,
    DropdownEditor,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      groups: [],
      createModal: {
        showModal: false,
      },
      editModal: {
        showModal: false,
        groupKey: null,
      },
    };
  },
  methods: {
    fetchGroups() {
      this.loadingCount++;
      this.$http
        .get('/select/groups')
        .then((res) => {
          this.groups = res.body.groups;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch groups: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    createGroup() {
      this.createModal.showModal = true;
    },
    editGroup(group) {
      this.editModal.groupKey = group.group_key;
      this.editModal.showModal = true;
    },
    onCreated(group) {
      this.fetchGroups();
      this.editGroup(group);
    },
    onUpdated() {
      this.fetchGroups();
    },
    onDeleted(groupKey) {
      this.groups = this.groups.filter((group) => group.group_key !== groupKey);
    },
  },
  created() {
    this.fetchGroups();
  },
};
</script>

<style lang="scss" scoped>
</style>
